// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-index-jsx": () => import("./../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-briefing-paper-index-jsx": () => import("./../src/pages/briefing-paper/index.jsx" /* webpackChunkName: "component---src-pages-briefing-paper-index-jsx" */),
  "component---src-pages-data-story-index-jsx": () => import("./../src/pages/data-story/index.jsx" /* webpackChunkName: "component---src-pages-data-story-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-infographic-index-jsx": () => import("./../src/pages/infographic/index.jsx" /* webpackChunkName: "component---src-pages-infographic-index-jsx" */),
  "component---src-pages-story-cybersecurity-jsx": () => import("./../src/pages/story/cybersecurity.jsx" /* webpackChunkName: "component---src-pages-story-cybersecurity-jsx" */),
  "component---src-pages-story-edtech-jsx": () => import("./../src/pages/story/edtech.jsx" /* webpackChunkName: "component---src-pages-story-edtech-jsx" */),
  "component---src-pages-story-fintech-jsx": () => import("./../src/pages/story/fintech.jsx" /* webpackChunkName: "component---src-pages-story-fintech-jsx" */),
  "component---src-pages-story-smart-cities-jsx": () => import("./../src/pages/story/smart-cities.jsx" /* webpackChunkName: "component---src-pages-story-smart-cities-jsx" */)
}

